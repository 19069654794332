import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { navigate } from 'gatsby';
import { Container, Row, Col } from 'react-grid-system';
import classNames from 'classnames/bind';
import Ionicon from 'react-ionicons';

import styles from './Form.module.scss';
import StoreSelector from './StoreSelector';
import { vocActions } from '../../../actions';
import GuideMessage from '../common/GuideMessage';
import Agreement from '../common/Agreement';
import categories from '../../../datas/voc/category.json';
import types from '../../../datas/voc/type.json';

const cx = classNames.bind(styles);

class VOCForm extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    const newState = {};

    const { isProcessing } = nextProps.voc;
    if (prevState.isProcessing !== isProcessing) {
      newState.isProcessing = isProcessing;
    }

    // 변경내용 존재하지 않는 경우
    if (Object.keys(newState).length === 0) {
      return null;
    }
    // 변경 내용 존재하는 경우
    else {
      return newState;
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      selectedCategory: 0,
      selectedItem: 0,
      isAgree: false,
      isProcessing: props.voc.isProcessing,
    };

    // Refs.
    this.visitDateInput = React.createRef();
    this.nameInput = React.createRef();
    this.emailInput = React.createRef();
    this.telInput = React.createRef();
    this.titleInput = React.createRef();
    this.contentInput = React.createRef();
    this.attachFileInput1 = React.createRef();
    this.attachFileInput2 = React.createRef();
    this.attachFileInput3 = React.createRef();
    this.attachFileInput4 = React.createRef();
    this.attachFileInput5 = React.createRef();
  }

  render() {
    const { selectedCategory, selectedItem, isAgree, isProcessing } = this.state;

    return (
      <div className={cx('contentWrapper')}>
        <Container>
          <Row align="start">
            <Col xs={12} lg={5} offset={{ lg: 1 }} className={cx('column')}>
              <StoreSelector onRef={ref => (this.storeSelector = ref)} disabled={isProcessing} />
              <div className={cx('items')}>
                {types.map((type, i) => {
                  const isSelected = selectedItem === i;

                  return (
                    <div
                      key={i}
                      className={cx('item', { selected: isSelected })}
                      onClick={this.selectItem.bind(this, i)}>
                      {isSelected && <Ionicon icon="md-checkmark" fontSize="17px" className={cx('icon')} />}
                      <div className={cx('text')}>{type.name}</div>
                    </div>
                  );
                })}
              </div>
              <div className={cx('items')}>
                {categories.map((category, i) => {
                  const isSelected = selectedCategory === i;

                  return (
                    <div
                      key={i}
                      className={cx('item', { selected: isSelected })}
                      onClick={this.selectCategory.bind(this, i)}>
                      {isSelected && <Ionicon icon="md-checkmark" fontSize="17px" className={cx('icon')} />}
                      <div className={cx('text')}>{category.name}</div>
                    </div>
                  );
                })}
              </div>
              <input
                type="text"
                ref={this.visitDateInput}
                placeholder="방문일자 (YYYYMMDD)"
                disabled={isProcessing}
                maxLength={8}
                className={cx('field')}
              />
              <input
                type="text"
                ref={this.nameInput}
                placeholder="성명"
                disabled={isProcessing}
                className={cx('field')}
              />
              <input
                type="text"
                ref={this.emailInput}
                placeholder="이메일"
                disabled={isProcessing}
                className={cx('field')}
              />
              <input
                type="text"
                ref={this.telInput}
                placeholder="전화번호 ('-' 제외 및 숫자만 입력)"
                disabled={isProcessing}
                className={cx('field')}
              />
              <input
                type="text"
                ref={this.titleInput}
                placeholder="제목"
                disabled={isProcessing}
                className={cx('field')}
              />
              <textarea
                type="text"
                ref={this.contentInput}
                placeholder="내용"
                className={cx('textarea')}
                rows={6}
                maxLength={1000}
                disabled={isProcessing}></textarea>
              <div className={cx('attachWrapper')}>
                <input type="file" ref={this.attachFileInput1} className={cx('attachFile')} />
                <input type="file" ref={this.attachFileInput2} className={cx('attachFile')} />
                <input type="file" ref={this.attachFileInput3} className={cx('attachFile')} />
                <input type="file" ref={this.attachFileInput4} className={cx('attachFile')} />
                <input type="file" ref={this.attachFileInput5} className={cx('attachFile')} />
              </div>
            </Col>
            <Col xs={12} lg={4.5} offset={{ lg: 0.5 }} className={cx('column')}>
              <GuideMessage>
                본 고객의 소리는
                <br />
                <span className={cx('bold')}>이메일을 통한 상담</span>으로만 진행됩니다.
                <br />
                보다 나은 서비스로 보답 하겠습니다.
                <br />
                문의가 집중되거나 주말의 경우
                <br />
                답변이 지연될 수 있으니 양해 부탁 드립니다.
                <div className={cx('timeTitle')}>고객의 소리 운영시간</div>
                <div className={cx('timeText')}>월 ~ 금 : 09:00 ~ 17:00 (토/일요일, 공휴일 휴무)</div>
              </GuideMessage>
              <Agreement />
              <div className={cx('buttonWrapper')}>
                <div className={cx('checkWrapper')}>
                  <div className={cx('checkBox')} onClick={this.toggleCheck}>
                    {isAgree && <Ionicon icon="md-checkmark" fontSize="17px" />}
                  </div>
                  <div className={cx('agreeText')}>상기 개인정보 수집 및 이용에 동의합니다.</div>
                </div>
                <div className={cx('sendButton')} onClick={this.applyForm}>
                  <div className={cx('text')}>{isProcessing ? '등록 중' : '접수하기'}</div>
                  <Ionicon icon="ios-arrow-forward" fontSize="17px" className={cx('arrow')} />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { isProcessing: isProcessed } = prevState;
    const { isProcessing, failure } = this.props.voc;

    if (isProcessed && !isProcessing && !failure) {
      alert('정상적으로 등록이 완료되었습니다.');
      navigate('/'); // 등록 완료 후 홈 이동
    }
  }

  selectCategory = idx => {
    const { isProcessing } = this.state;

    // 등록 중인 아닌 경우
    if (!isProcessing) {
      this.setState({
        selectedCategory: idx,
      });
    }
  };

  selectItem = idx => {
    const { isProcessing } = this.state;

    // 등록 중인 아닌 경우
    if (!isProcessing) {
      this.setState({
        selectedItem: idx,
      });
    }
  };

  toggleCheck = () => {
    const { isAgree, isProcessing } = this.state;

    // 등록 중인 아닌 경우
    if (!isProcessing) {
      this.setState({
        isAgree: !isAgree,
      });
    }
  };

  applyForm = () => {
    const { selectedCategory, selectedItem, isProcessing } = this.state;

    // 등록 중인 아니고, 입력내용 검증을 통과한 경우
    if (!isProcessing && this.validateForm()) {
      const data = new FormData();
      data.append('store_code', this.storeSelector.getSelectedStore().value);
      data.append('visit_date', this.visitDateInput.current.value);
      data.append('name', this.nameInput.current.value);
      data.append('email', this.emailInput.current.value);
      data.append('tel', this.telInput.current.value);
      data.append('category', categories[selectedCategory].code);
      data.append('type', types[selectedItem].code);
      data.append('title', this.titleInput.current.value);
      data.append('content', this.contentInput.current.value);
      data.append('attach', this.attachFileInput1.current.files[0]);
      data.append('attach', this.attachFileInput2.current.files[0]);
      data.append('attach', this.attachFileInput3.current.files[0]);
      data.append('attach', this.attachFileInput4.current.files[0]);
      data.append('attach', this.attachFileInput5.current.files[0]);

      this.props.actions.vocActions.request(data);
    }
  };

  validateForm = () => {
    const { isAgree } = this.state;

    const selectedStore = this.storeSelector.getSelectedStore();
    const visitDate = this.visitDateInput.current.value.trim();
    const name = this.nameInput.current.value.trim();
    const email = this.emailInput.current.value.trim();
    const tel = this.telInput.current.value.trim();
    const title = this.titleInput.current.value.trim();
    const content = this.contentInput.current.value.trim();

    // 방문매장
    if (selectedStore === null) {
      alert('방문매장을 선택해주세요.');
      return false;
    }

    // 방문일자
    if (visitDate === '') {
      alert('방문일자를 입력해주세요.');
      this.visitDateInput.current.focus();
      return false;
    } else if (!/^20[1-2]{1}\d{5}$/.test(visitDate)) {
      alert('방문일자 형식을 확인해주세요.');
      this.visitDateInput.current.focus();
      return false;
    }

    // 성명
    if (name === '') {
      alert('성명을 입력해주세요.');
      this.nameInput.current.focus();
      return false;
    }

    // 이메일
    if (email === '') {
      alert('이메일을 입력해주세요.');
      this.emailInput.current.focus();
      return false;
    } else if (!/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
      alert('이메일 형식을 확인해주세요.');
      this.emailInput.current.focus();
      return false;
    }

    // 전화번호
    if (tel === '') {
      alert('전화번호를 입력해주세요.');
      this.telInput.current.focus();
      return false;
    } else if (!/^[0-9]+$/.test(tel)) {
      alert('전화번호 형식을 확인해주세요.');
      this.telInput.current.focus();
      return false;
    }

    // 제목
    if (title === '') {
      alert('제목을 입력해주세요.');
      this.titleInput.current.focus();
      return false;
    }

    // 내용
    if (content === '') {
      alert('내용을 입력해주세요.');
      this.contentInput.current.focus();
      return false;
    }

    // 동의
    if (!isAgree) {
      alert('개인정보 수집 및 이용에 동의해주세요.');
      return false;
    }

    return true;
  };
}

/**
 * Define redux settings
 */
const mapStateToProps = state => {
  const { voc } = state;
  return {
    voc,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: {
      vocActions: bindActionCreators(vocActions, dispatch),
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VOCForm);
