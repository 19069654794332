import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Select from 'react-select';

import { storeActions } from '../../../actions';

const customStyles = {
  container: (base, state) => ({
    ...base,
    marginBottom: 24,
  }),
  control: (base, state) => ({
    ...base,
    borderWidth: 0,
    borderRadius: 0,
    backgroundColor: 'white',
    boxShadow: 'none',
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: '#584934 !important',
  }),
  input: (base, state) => ({
    ...base,
    fontSize: 13,
  }),
  placeholder: (base, state) => ({
    ...base,
    fontSize: 13,
  }),
  option: (base, state) => ({
    ...base,
    fontSize: 13,
    backgroundColor: state.isSelected || state.isFocused ? '#fdfbee !important' : 'white !important',
    color: 'black',
  }),
  valueContainer: (base, state) => ({
    ...base,
    paddingLeft: 0,
    fontSize: 13,
  }),
};

class StoreSelector extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    const newState = {};

    // 매장내역 업데이트
    const { isProcessing, failure, data } = nextProps.store;

    if (!isProcessing && !failure && data.length > 0) {
      if (prevState.options.length !== data.length) {
        const options = [];
        options.push({
          label: '본사관리',
          value: '1000000000',
        });

        // TODO
        data
          .sort((a, b) => (a.name > b.name ? 1 : -1))
          .forEach(store => {
            options.push({
              label: store.name,
              value: store.code,
            });
          });

        newState.options = options;
      }
    }

    // 활성/비활성 상태 업데이트
    if (prevState.isDisabled !== nextProps.disabled) {
      newState.isDisabled = nextProps.disabled;
    }

    // 변경내용 존재하지 않는 경우
    if (Object.keys(newState).length === 0) {
      return null;
    }
    // 변경 내용 존재하는 경우
    else {
      return newState;
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      options: [],
      isDisabled: props.disabled,
      selectedStore: null,
    };
  }

  componentDidMount() {
    this.props.onRef(this);

    if (this.props.store.data.length === 0) {
      this.props.actions.storeActions.request();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { isProcessing, failure } = nextProps.store;
    return !isProcessing && !failure;
  }

  componentWillUnmount() {
    this.props.onRef(undefined);
  }

  render() {
    const { options, isDisabled } = this.state;

    return (
      <Select
        ref="code"
        placeholder="방문매장"
        options={options}
        onChange={this._handleChange}
        styles={customStyles}
        isDisabled={isDisabled}
      />
    );
  }

  _handleChange = selectedStore => {
    this.setState({
      selectedStore,
    });
  };

  getSelectedStore = () => {
    return this.state.selectedStore;
  };
}

/**
 * Define redux settings
 */
const mapStateToProps = state => {
  const { store } = state;
  return {
    store,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: {
      storeActions: bindActionCreators(storeActions, dispatch),
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StoreSelector);
