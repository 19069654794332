import React from 'react';

import PageTitleBar from '../../components/misc/PageTitleBar';
import Form from '../../components/Contact/VOC/Form';
import GrayBox from '../../components/misc/GrayBox';

const VOCPage = () => (
  <div>
    <PageTitleBar title="Voice of the customer" backgroundImage={require('../../assets/images/banner/voc.jpg')} />
    <Form />
    <GrayBox
      messages={[
        '고객님께서 동의한 개인정보처리방침에 따라 불만처리를 위해 본 양식에 기재한 개인정보를 활용할 수 있습니다.',
        '답변은 직접 기재하여 주신 메일주소로 발송되며, 기재 오류 및 계정문제가 발생할 경우 답변이 불가할 수 있으므로 메일주소를 꼭 확인해주시기 바랍니다.',
      ]}
    />
  </div>
);

export default VOCPage;
